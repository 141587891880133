.home-program {
  height: 70vh;
  background: linear-gradient(
    to bottom,
    #dbdbe1 0%,
    #dbdbe1 70%,
    white 70%,
    white 100%
  );
  display: flex;
  flex-direction: column;
  gap: 15%;
  position: relative;
  z-index: -1;
}
.home-program-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 2%;
}
.home-about-text-header-section {
  letter-spacing: 1px;
}
.image-small {
  width: 300px;
  height: 300px;
}
.home-image-section {
}
.home-image-carousel {
  display: flex;
  gap: 2%;
  margin: 0px 15%;
  overflow-x: scroll;
  overflow-y: hidden;
  /* text-overflow: auto; */
}

.home-image-carousel::-webkit-scrollbar {
  display: none;
}

.home-image-single {
  width: 250px;
  height: 250px;
  cursor: pointer;
  transition: transform 500ms;
  position: relative;
}
.home-image-buttons {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.home-image-buttons span {
  padding: 10px 13px;
  border-radius: 100%;
  border: 1px solid gray;
  color: gray;
  cursor: pointer;
  font-size: 30px;
  font-weight: bolder;
}

.home-image-single:hover {
  transform: scale(1.08);
  opacity: 1;
}
