.home-contact-middle {
  height: 20vh;
  background-color: #24345d;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  gap: 5%;
  position: relative;
  z-index: -1;
}
.home-contact-header {
  width: 35%;
  line-height: 50px;
}
.home-contact-form-section {
  width: 50%;
}

.home-contact-form {
  display: flex;
  align-items: center;
  gap: 5%;
}
.home-contact-form-input {
  font-size: 15px;
  width: 17em;
  background-color: #24345d;
  border: 1px solid gray;
  color: white;
  padding: 15px;
}
input:focus {
  border-color: #08fdd8;
}

.submit {
  background-color: #0ebda2;
  color: black;
}
.submit:hover {
  transition: ease-in all;
  background-color: #52c306;
}

.home-contact-form-popup {
  margin-top: 10px;
  width: 100%;
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 2px solid #08fdd8;
}
.close-button {
  cursor: pointer;
  font-size: 20px;
}
