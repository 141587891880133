.suki-home {
  display: flex;
  justify-content: center;
  gap: 50px;
  height: 60vh;
  margin-top: 5%;
  position: relative;
  z-index: -1;
}
.home-about-images {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
  position: relative;
  z-index: -1;
}
.home-about-image-one {
  height: 300px;
  width: 400px;
}
.home-about-image-two {
  position: absolute;
  height: 300px;
  width: 250px;
  top: 200px;
  left: -150px;
  box-shadow: 18px -18px 6px 5px #ffffff;
}
.home-about-text {
  display: flex;
  flex-direction: column;
  gap: 10%;
  width: 30%;
  letter-spacing: 1px;
}
.home-about-text-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home-about-text-header-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.horizontal-rule {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 2px;
}
hr {
  width: 60px;
  height: 4px;
  background-color: #00b050;
  border-color: #00b050;
}
.horizontal-rule-dotted {
  height: 4px;
  background-color: #00b050;
  width: 5px;
  border-color: #00b050;
}
.home-about-text-header {
  font-weight: bold;
  font-size: 40px;
}
.home-about-text-quote {
  color: #00b050;
}
.home-about-text-gifs {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.home-about-text-gif-one {
  height: 100px;
  width: 100px;
}
.vertical-line {
  border-left: 0.5px dashed gray;
}
