.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 9%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background-color: rgb(247, 243, 243);
  text-decoration: none;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.78);
}
.header-logo-section {
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: none;
  font-family: "Fuggles", cursive;
  font-family: "Syne Tactile", cursive;
  color: black;
}

.menu-header-items {
  display: flex;
  gap: 40px;
}
.menu-item {
  display: flex;
  text-decoration: none;
  color: black;
  position: relative;
  font-size: 20px;
  opacity: 0.9;
  padding-bottom: 10px;
  font-weight: bold;
}
.menu-item:hover {
  opacity: 1;
  color: #941bb3;
}
.menu-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #971b1b;
  transition: all 0.45s;
}

.menu-item:hover::before {
  width: 100%;
}
