body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.app {
  position: relative;
}
.top-image {
  width: 100%;
  height: 100vh;
}
