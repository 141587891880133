.about-section {
  height: 100vh;
  background-color: gray;
  position: relative;
}
.box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 200px;
  width: 400px;
  background-color: lightgray;
  margin-left: 800px;
  flex-direction: column;
  z-index: -1;
}
